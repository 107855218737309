
import { defineComponent, reactive, provide } from "vue";
import HomeHead from "@/components/index/home-head.vue";
import HomeMenu from "@/components/index/home-menu.vue";
import DataMain from "@/components/index/main/data/data-main.vue";
import TenantMain from "@/components/index/main/tenant/tenant-main.vue";
import TenantConfigMain from "@/components/index/main/tenant-config/tenant-config-main.vue"
import UserMain from "@/components/index/main/user/user-main.vue";
import VerifyMain from "@/components/index/main/verify/verify-main.vue";
import VerifyRecordMain from "@/components/index/main/verify-record/verify-record-main.vue"

interface DataHome {
  //主页面正在展示的类型
  mainType: string;
  //获取主页展示的类型
  getMainType: (mainType: string) => void;
}
export default defineComponent({
  setup(props, context) {
    const state = reactive({
      navIndex: 0,
    });
    const choseNav = (param: number) => {
      state.navIndex = param;
    };

    const data: DataHome = reactive({
      mainType: "1",
      getMainType(mainType: string) {
        data.mainType = mainType;
      },
    });
    return { data, choseNav, state };
  },
  //接收子组件参数
  components: {
    HomeHead,
    HomeMenu,
    DataMain,
    TenantMain,
    TenantConfigMain,
    UserMain,
    VerifyMain,
    VerifyRecordMain,
  },
});
