import request from "../http";

/**
 * 获取所有的租户信息
 * @param req 查询条件
 * @returns 
 */
export const pageUser = (req:any) => {
    return request({
        url: '/by-user/user/page',
        method: 'post',
        data: req
    })
}

/**
 * 查询用户详情
 */
export const userDetail = (req:any) => {
    return request({
        url: '/by-user/user/detail',
        method: 'post',
        data: req
    })
}