
import { defineComponent, onMounted, reactive } from 'vue';
import {pageVerify, delVerify, pageRecord, passRecord, rejectRecord} from '@/http/api/verify';
import { da, fa } from 'element-plus/es/locale';
import {time} from '@/utils/dateUtil';
import { ElMessage, ElMessageBox } from 'element-plus'



interface DataVerifyApprove{
    //认证记录查询条件
    recordReq:{
        pageNum:any,
        pageSize:any,
        //时间范围
        time:Array<string>,
        startTime:any,
        endTime:any
    }
    //总数据量
    total:any;
    //认证列表
    verifyList:Array<Object>;
    //认证记录
    verifyRecords:Array<Object>;
    //审批通过的dialog
    passDialogVis:boolean;
    //审批拒绝的dialog
    rejectDialogVis:boolean;
    //详情的dialog
    detailDialogVis:boolean;
    //认证审批
    verifyRecord:{
        id:string
    };
    //审批通过参数
    passReq:{
        reason:string
    };
    //审批拒绝参数
    rejectReq:{
        reason:string
    }
    //查询
    query: () => void
    //分页变更
    pageChange: () => void;
    //记录详情
    detail: (record:any) => void;
    //审批同意
    pass: (record:any) => void;
    //审批拒绝
    reject: (record:any) => void;
    //取消dialog
    cancelDialog: (type:string) => void;
    //审批通过提交
    passSubmit: () => void;
    //审批拒绝提交
    rejectSubmit: () => void;
}
export default defineComponent({
    setup() {
        onMounted(()=>{
            //获取所有认证信息
            pageVerify({pageSize:1000,pageNum:1}).then((res)=>{
                data.verifyList = res.data.data.list
            }),
            //获取第一页认证记录信息
            pageRecord(data.recordReq).then((res) => {
                console.log(res)
                let list = res.data.data.list
                if (list !== null && list.length > 0) {
                    for(let i = 0; i< list.length; i++){
                        //处理时间
                        list[i].createTimeStr = time.long2Date(list[i].createTime)
                    }
                }
                data.verifyRecords = list
                data.total = Number(res.data.data.total)
            })
        })

        const data : DataVerifyApprove = reactive({
            recordReq:{
                pageNum:1,
                pageSize:10,
                time:[],
                startTime:null,
                endTime:null
            },
            total:0,
            verifyList:[],
            verifyRecords:[],
            passDialogVis:false,
            rejectDialogVis:false,
            detailDialogVis:false,
            verifyRecord:{
                id: ''
            },
            passReq:{
                reason:''
            },
            rejectReq:{
                reason:''
            },
            query(){
                //处理查询条件时间
                if (data.recordReq.time !== null && data.recordReq.time.length === 2) {
                    let start = new Date(data.recordReq.time[0])
                    data.recordReq.startTime = start
                    let end = new Date(data.recordReq.time[1])
                    data.recordReq.endTime = end
                }else{
                    data.recordReq.startTime = null
                    data.recordReq.endTime = null
                }
                pageRecord(data.recordReq).then((res) => {
                    console.log(res)
                    let list = res.data.data.list
                    if (list !== null && list.length > 0) {
                        for(let i = 0; i< list.length; i++){
                            //处理时间
                            list[i].createTimeStr = time.long2Date(list[i].createTime)
                        }
                    }
                    data.verifyRecords = list
                    data.total = Number(res.data.data.total)
                })
            },
            pageChange(){
                pageRecord(data.recordReq).then((res) => {
                    console.log(res)
                    let list = res.data.data.list
                    if (list !== null && list.length > 0) {
                        for(let i = 0; i< list.length; i++){
                            //处理时间
                            list[i].createTimeStr = time.long2Date(list[i].createTime)
                        }
                    }
                    data.verifyRecords = list
                    data.total = Number(res.data.data.total)
                })
            },
            detail(record:any){
                data.verifyRecord = record
                data.detailDialogVis = true
            },
            pass(record:any){
                data.verifyRecord = record
                data.passDialogVis = true
            },
            reject(record:any){
                data.verifyRecord = record
                data.rejectDialogVis = true
            },
            cancelDialog(type:string){
                if(type === 'pass'){
                    data.passDialogVis = false
                }else if(type === 'reject'){
                    data.rejectDialogVis = false
                }else if(type === 'detail'){
                    data.detailDialogVis = false
                }
                data.verifyRecord = {
                    id: ''
                }
            },
            passSubmit(){
                let req = {
                    recordId:data.verifyRecord.id,
                    reason: data.passReq.reason
                }
                passRecord(req).then((res) => {
                    console.log(res)
                })
                data.passDialogVis = false
                //重新请求
                this.query()
            },
            rejectSubmit(){
                let req = {
                    recordId:data.verifyRecord.id,
                    reason: data.rejectReq.reason
                }
                console.log(req)
                rejectRecord(req).then((res) => {
                    console.log(res)
                })
                data.rejectDialogVis = false
            }
        })
        return {data}
    },
})
