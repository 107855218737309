import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bcc7e290"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "verify_add" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_el_checkbox_group = _resolveComponent("el-checkbox-group")!
  const _component_el_switch = _resolveComponent("el-switch")!
  const _component_el_radio = _resolveComponent("el-radio")!
  const _component_el_radio_group = _resolveComponent("el-radio-group")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_form, {
      model: _ctx.data.verify,
      "label-width": "120px"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_form_item, { label: "认证名称：" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              modelValue: _ctx.data.verify.name,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.data.verify.name) = $event)),
              class: "verify_name",
              placeholder: "请输入认证名称",
              "show-word-limit": "",
              maxlength: "10"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, { label: "认证主体：" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_checkbox_group, {
              modelValue: _ctx.data.verify.subjects,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.data.verify.subjects) = $event))
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.verifySubjects, (subject) => {
                  return (_openBlock(), _createBlock(_component_el_checkbox, {
                    key: subject.code,
                    label: subject.code,
                    border: ""
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(subject.name), 1)
                    ]),
                    _: 2
                  }, 1032, ["label"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, { label: "自动监测：" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_switch, {
              modelValue: _ctx.data.verify.boolAuto,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.data.verify.boolAuto) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        (_ctx.data.verify.boolAuto)
          ? (_openBlock(), _createBlock(_component_el_form_item, {
              key: 0,
              label: "自动监测类型："
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_radio_group, {
                  modelValue: _ctx.data.verify.autoType,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.data.verify.autoType) = $event))
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.verifyAuto, (auto) => {
                      return (_openBlock(), _createBlock(_component_el_radio, {
                        class: "verify_add_auto_type",
                        key: auto,
                        border: "",
                        label: auto.type
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(auto.name), 1)
                        ]),
                        _: 2
                      }, 1032, ["label"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.data.verify.boolAuto)
          ? (_openBlock(), _createBlock(_component_el_form_item, {
              key: 1,
              label: "自动通过："
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_switch, {
                  modelValue: _ctx.data.verify.boolAutoPass,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.data.verify.boolAutoPass) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createVNode(_component_el_form_item, { label: "备注：" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              class: "verify_remark",
              modelValue: _ctx.data.verify.remark,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.data.verify.remark) = $event)),
              autosize: { minRows: 2, maxRows: 4 },
              type: "textarea",
              placeholder: "备注"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_button, {
              type: "primary",
              onClick: _ctx.data.submit
            }, {
              default: _withCtx(() => [
                _createTextVNode("提交")
              ]),
              _: 1
            }, 8, ["onClick"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["model"])
  ]))
}