import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3d08a0aa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "user-main" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_breadcrumb_item = _resolveComponent("el-breadcrumb-item")!
  const _component_el_breadcrumb = _resolveComponent("el-breadcrumb")!
  const _component_el_card = _resolveComponent("el-card")!
  const _component_user_table = _resolveComponent("user-table")!
  const _component_user_detail = _resolveComponent("user-detail")!
  const _component_user_reported = _resolveComponent("user-reported")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_card, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_breadcrumb, { separator: "/" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_breadcrumb_item, {
              to: { path: '/' },
              onClick: _ctx.data.toTable
            }, {
              default: _withCtx(() => [
                _createTextVNode("用户列表")
              ]),
              _: 1
            }, 8, ["onClick"]),
            (_ctx.data.pageType === 'detail')
              ? (_openBlock(), _createBlock(_component_el_breadcrumb_item, { key: 0 }, {
                  default: _withCtx(() => [
                    _createTextVNode("用户详情")
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.data.pageType === 'reported')
              ? (_openBlock(), _createBlock(_component_el_breadcrumb_item, { key: 1 }, {
                  default: _withCtx(() => [
                    _createTextVNode("被举报列表")
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_el_card, { class: "user-main-table" }, {
      default: _withCtx(() => [
        (_ctx.data.pageType == 'table')
          ? (_openBlock(), _createBlock(_component_user_table, {
              key: 0,
              onUserDetail: _ctx.data.userDetail,
              onUserReported: _ctx.data.userReported
            }, null, 8, ["onUserDetail", "onUserReported"]))
          : _createCommentVNode("", true),
        (_ctx.data.pageType == 'detail')
          ? (_openBlock(), _createBlock(_component_user_detail, {
              key: 1,
              userId: _ctx.data.userId,
              onToTable: _ctx.data.toTable
            }, null, 8, ["userId", "onToTable"]))
          : (_ctx.data.pageType == 'reported')
            ? (_openBlock(), _createBlock(_component_user_reported, {
                key: 2,
                userId: _ctx.data.userId
              }, null, 8, ["userId"]))
            : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}