
import { defineComponent, onMounted, reactive } from 'vue';
import {getLoginUser} from '@/http/api/login'

interface DataHomeHead {
    //当前登录用户
    user:Object
}
export default defineComponent({
    setup() {
        onMounted(() => {
            //获取用户信息
            getLoginUser().then((res) => {
                if (res.data.data !== null) {
                    data.user = res.data.data
                }
            })
        })
        const data : DataHomeHead = reactive({
            //用户
            user:{}
        })
        return {data}
    },
})
