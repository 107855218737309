import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d917ba7e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "user-detail" }
const _hoisted_2 = { class: "user-detail-operation" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_avatar = _resolveComponent("el-avatar")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_button = _resolveComponent("el-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_form, {
      model: _ctx.data.user,
      "label-width": "auto",
      style: {"max-width":"600px"}
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_form_item, { label: "昵称：" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.data.user.nickName), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, { label: "头像：" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_avatar, {
              shape: "square",
              src: _ctx.data.user.headUrl
            }, null, 8, ["src"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, { label: "手机号：" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.data.user.phone), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, { label: "微信号：" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.data.user.wechat), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, { label: "位置：" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.data.user.location), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, { label: "简介：" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.data.user.introduce), 1)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["model"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_el_button, {
        onClick: _ctx.data.toTable
      }, {
        default: _withCtx(() => [
          _createTextVNode("返回")
        ]),
        _: 1
      }, 8, ["onClick"])
    ])
  ]))
}