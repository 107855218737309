import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1208c29a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tenant_detail_operation" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_button = _resolveComponent("el-button")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_form, { "label-width": "80px" }, {
      default: _withCtx(() => [
        _createVNode(_component_el_form_item, { label: "租户名字:" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.data.tenant.name), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, { label: "租户编码:" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.data.tenant.tenantCode), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, { label: "省:" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.data.tenant.provinceName) + "（" + _toDisplayString(_ctx.data.tenant.provinceCode) + "）", 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, { label: "市:" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.data.tenant.cityName) + "（" + _toDisplayString(_ctx.data.tenant.cityCode) + "）", 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, { label: "区:" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.data.tenant.countyName) + "（" + _toDisplayString(_ctx.data.tenant.countyCode) + "）", 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, { label: "位置:" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.data.tenant.location), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, { label: "创建人:" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.data.tenant.creatorName), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, { label: "创建时间:" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.data.tenant.createTime), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, { label: "描述:" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.data.tenant.des), 1)
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_el_button, {
        onClick: _ctx.data.toTable
      }, {
        default: _withCtx(() => [
          _createTextVNode("返回")
        ]),
        _: 1
      }, 8, ["onClick"]),
      _createVNode(_component_el_button, {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.data.tenantEdit(_ctx.data.tenant.id)))
      }, {
        default: _withCtx(() => [
          _createTextVNode("更新")
        ]),
        _: 1
      })
    ])
  ]))
}