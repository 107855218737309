import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-80fbd056"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "index" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 2 }
const _hoisted_5 = { key: 3 }
const _hoisted_6 = { key: 4 }
const _hoisted_7 = { key: 5 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_home_menu = _resolveComponent("home-menu")!
  const _component_el_aside = _resolveComponent("el-aside")!
  const _component_home_head = _resolveComponent("home-head")!
  const _component_el_header = _resolveComponent("el-header")!
  const _component_tenant_main = _resolveComponent("tenant-main")!
  const _component_tenant_config_main = _resolveComponent("tenant-config-main")!
  const _component_user_main = _resolveComponent("user-main")!
  const _component_verify_main = _resolveComponent("verify-main")!
  const _component_verify_record_main = _resolveComponent("verify-record-main")!
  const _component_data_main = _resolveComponent("data-main")!
  const _component_el_card = _resolveComponent("el-card")!
  const _component_el_main = _resolveComponent("el-main")!
  const _component_el_container = _resolveComponent("el-container")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_container, { class: "index-container" }, {
      default: _withCtx(() => [
        _createVNode(_component_el_aside, {
          class: "index-menu",
          width: "180px"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_home_menu, {
              onGetMainType: _ctx.data.getMainType
            }, null, 8, ["onGetMainType"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_container, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_header, { height: "56px" }, {
              default: _withCtx(() => [
                _createVNode(_component_home_head)
              ]),
              _: 1
            }),
            _createVNode(_component_el_main, null, {
              default: _withCtx(() => [
                _createVNode(_component_el_card, null, {
                  default: _withCtx(() => [
                    (_ctx.data.mainType == '1-1')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                          _createVNode(_component_tenant_main)
                        ]))
                      : _createCommentVNode("", true),
                    (_ctx.data.mainType == '1-2')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                          _createVNode(_component_tenant_config_main)
                        ]))
                      : (_ctx.data.mainType == '2-1')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                            _createVNode(_component_user_main)
                          ]))
                        : (_ctx.data.mainType == '3-1')
                          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                              _createVNode(_component_verify_main)
                            ]))
                          : (_ctx.data.mainType == '3-2')
                            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                                _createVNode(_component_verify_record_main)
                              ]))
                            : (_ctx.data.mainType == '4-1')
                              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                                  _createVNode(_component_data_main)
                                ]))
                              : _createCommentVNode("", true)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}