import request from "../http";

/**
 * 获取所有自动认证类型
 */
 export const getAllAutoType = () =>{
    return request({
        url: '/by-user/verified/getAllAutoType',
        method: 'post',
        data: null
    })
}

/**
 * 获取所有认证主体
 */
 export const getAllSubject = () =>{
    return request({
        url: '/by-user/verified/getAllSubject',
        method: 'post',
        data: null
    })
}

/**
 * 新增认证
 * @param req 新增参数
 */
export const addVerify = (req:any) => {
    return request({
        url: '/by-user/verified/add',
        method: 'post',
        data: req
    })
}

/**
 * 修改认证
 * @param req 修改参数
 */
export const updateVerify = (req:any) => {
    return request({
        url: '/by-user/verified/update',
        method: 'post',
        data: req
    })
}

/**
 * 分页获取认证信息
 * @param req 分页信息
 */
export const pageVerify = (req:any) => {
    return request({
        url: '/by-user/verified/page',
        method: 'post',
        data: req
    })
}

/**
 * 删除认证信息
 * @param req id
 */
export const delVerify = (req:any) => {
    return request({
        url: '/by-user/verified/del',
        method: 'post',
        data: req
    })
}

/**
 * 获取详情
 * @param req id
 */
export const detailVerify = (req:any) => {
    return request({
        url: '/by-user/verified/detail',
        method: 'post',
        data: req
    })
}

/**
 * 分页获取认证记录
 * @param req 查询条件
 * @returns 认证记录
 */
export const pageRecord = (req:any) => {
    return request({
        url: '/by-user/verify/record/page',
        method: 'post',
        data: req
    })
}

/**
 * 认证记录审批
 * @param req 条件
 */
export const passRecord = (req:any) => {
    return request({
        url: '/by-user/verify/record/pass',
        method: 'post',
        data: req
    })
}

/**
 * 认证记录拒绝
 * @param req 记录
 */
export const rejectRecord = (req:any) => {
    return request({
        url: '/by-user/verify/record/reject',
        method: 'post',
        data: req
    })
}