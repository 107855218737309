import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8dfbf998"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onClick"]
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "user-table-page" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_avatar = _resolveComponent("el-avatar")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_pagination = _resolveComponent("el-pagination")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_table, {
      data: _ctx.data.userList,
      stripe: "",
      style: {"width":"100%"}
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_table_column, {
          prop: "nickName",
          label: "昵称"
        }),
        _createVNode(_component_el_table_column, { label: "头像" }, {
          default: _withCtx((scope) => [
            _createVNode(_component_el_avatar, {
              shape: "square",
              src: scope.row.headUrl
            }, null, 8, ["src"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          prop: "phone",
          label: "手机号"
        }),
        _createVNode(_component_el_table_column, { label: "操作" }, {
          default: _withCtx((scope) => [
            _createElementVNode("span", {
              class: "content-table-handle",
              onClick: ($event: any) => (_ctx.data.userDetail(scope.row))
            }, "详情", 8, _hoisted_1),
            _createElementVNode("span", {
              class: "content-table-handle",
              onClick: ($event: any) => (_ctx.data.userReported(scope.row))
            }, "被举报信息", 8, _hoisted_2)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["data"]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_el_pagination, {
        small: "",
        background: "",
        layout: "prev, pager, next",
        total: _ctx.data.total,
        class: "mt-4",
        onCurrentChange: _ctx.data.pageChange
      }, null, 8, ["total", "onCurrentChange"])
    ])
  ]))
}