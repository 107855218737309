
import { defineComponent, onMounted, reactive } from "vue";
import { reportPage } from "@/http/api/comment";

interface DataUserReported {
  //查询条件
  req: {
    pageNum: number;
    pageSize: number;
  };
  //举报信息
  reportedList:Array<Object>
}

export default defineComponent({
  props: ["userId"],
  emits: [],
  setup(props, { emit }) {
    onMounted(() => {
      let userId = props.userId;
      let req = {
        pageNum: 1,
        pageSize: 10,
        userId: userId,
      };
      //查询举报
      reportPage(req).then((res) => {
        if (res.data.data.list !== null) {
            data.reportedList = res.data.data.list
        }
      });
    });
    const data: DataUserReported = reactive({
      req: {
        pageNum: 1,
        pageSize: 10,
      },
      //举报信息
      reportedList:[]
    });
    return { data };
  },
});
