import request from "../http";

/**
 * 校验手机号是否已经注册
 * @param phone 手机号
 */
export const checkPhone = (phone:String) =>{
    return request({
        url: '/by-user/admin/checkPhone',
        method: 'post',
        data: phone
    })
}

/**
 * 管理员登录
 * @param admin 管理员手机号、密码
 * @returns 请求返回
 */
export const loginUser = (admin:any) => {
    return request({
        url: '/by-user/admin/login',
        method: 'post',
        data: admin
    })
}

/**
 * 获取当前登录人的用户信息
 */
export const getLoginUser = () => {
    return request({
        url: '/by-user/admin/getLoginUser',
        method: 'post',
        data: null
    })
}