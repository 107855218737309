
import { defineComponent, onMounted, reactive } from "vue";
import { userDetail } from "@/http/api/user";

interface DataUserDetail {
  //用户
  user: Object;
  //跳转列表
  toTable: () => void;
}

export default defineComponent({
  props: ["userId"],
  emits: ["toTable"],
  setup(props, { emit }) {
    onMounted(() => {
      let userId = props.userId;
      userDetail({ id: userId }).then((res) => {
        if (res.data.data !== null) {
          data.user = res.data.data;
        }
      });
    });
    const data: DataUserDetail = reactive({
      //用户
      user: {},
      //跳转列表
      toTable: () => {
        emit('toTable')
      },
    });
    return { data };
  },
});
