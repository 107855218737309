import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6c1db54e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tenant_main" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_breadcrumb_item = _resolveComponent("el-breadcrumb-item")!
  const _component_el_breadcrumb = _resolveComponent("el-breadcrumb")!
  const _component_el_card = _resolveComponent("el-card")!
  const _component_tenant_table = _resolveComponent("tenant-table")!
  const _component_tenant_detail = _resolveComponent("tenant-detail")!
  const _component_tenant_update = _resolveComponent("tenant-update")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_card, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_breadcrumb, { separator: "/" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_breadcrumb_item, {
              to: { path: '/' },
              onClick: _ctx.data.toTable
            }, {
              default: _withCtx(() => [
                _createTextVNode("租户列表")
              ]),
              _: 1
            }, 8, ["onClick"]),
            (_ctx.data.pageType === 'detail')
              ? (_openBlock(), _createBlock(_component_el_breadcrumb_item, { key: 0 }, {
                  default: _withCtx(() => [
                    _createTextVNode("租户详情")
                  ]),
                  _: 1
                }))
              : (_ctx.data.pageType === 'edit')
                ? (_openBlock(), _createBlock(_component_el_breadcrumb_item, { key: 1 }, {
                    default: _withCtx(() => [
                      _createTextVNode("修改租户")
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_el_card, { class: "tenant_main_content" }, {
      default: _withCtx(() => [
        (_ctx.data.pageType === 'table')
          ? (_openBlock(), _createBlock(_component_tenant_table, {
              key: 0,
              onSetDetailTenantId: _ctx.data.setDetailTenantId,
              onTenantEdit: _ctx.data.tenantEdit
            }, null, 8, ["onSetDetailTenantId", "onTenantEdit"]))
          : (_ctx.data.pageType === 'detail')
            ? (_openBlock(), _createBlock(_component_tenant_detail, {
                key: 1,
                tenantId: _ctx.data.tenantId,
                onSetTable: _ctx.data.setTable,
                onTenantEdit: _ctx.data.tenantEdit
              }, null, 8, ["tenantId", "onSetTable", "onTenantEdit"]))
            : (_ctx.data.pageType === 'edit')
              ? (_openBlock(), _createBlock(_component_tenant_update, {
                  key: 2,
                  tenantId: _ctx.data.tenantId,
                  onSetTable: _ctx.data.setTable
                }, null, 8, ["tenantId", "onSetTable"]))
              : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}