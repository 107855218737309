import request from "../http";



/**
 * 新增租户管理员
 * @param req 新增条件
 */
export const addTenantAdmin = (req:any) => {
    return request({
        url: '/by-user/tenant/admin/add',
        method: 'post',
        data: req,
    })
}

/**
 * 获取租户主管理员信息
 * @param req 租户id
 */
export const getMainAdmin = (req:any) => {
    return request({
        url: '/by-user/tenant/admin/getMainAdmin',
        method: 'post',
        data: req
    })
}

/**
 * 获取所有的租户信息
 * @param req 查询条件
 * @returns 
 */
export const getAllTenant = (req:any) => {
    return request({
        url: '/by-user/tenant/getAllTenant',
        method: 'post',
        data: req
    })
}

/**
 * 分页查询租户信息
 */
export const pageTenant = (req:any) => {
    return request({
        url: '/by-user/tenant/page',
        method: 'post',
        data: req
    })
}

/**
 * 获取租户详情
 */
export const detailTenant = (req:any) => {
    return request({
        url: '/by-user/tenant/detail',
        method: 'post',
        data: req
    })
}

/**
 * 新增租户
 */
export const addTenant = (req:any) => {
    return request({
        url: '/by-user/tenant/add',
        method: 'post',
        data: req
    })
}

/**
 * 修改租户
 */
export const updateTenant = (req:any) => {
    return request({
        url: '/by-user/tenant/update',
        method: 'post',
        data: req
    })
}

/**
 * 删除租户
 */
export const delTenant = (req:any) => {
    return request({
        url: '/by-user/tenant/del',
        method: 'post',
        data: req
    })
}

/**
 * 获取租户配置
 */
export const queryConfig = (req:any) => {
    return request({
        url: '/by-user/tenant/queryConfig',
        method: 'post',
        data: req
    })
}

/**
 * 更新配置
 */
export const updateConfig = (req:any) => {
    return request({
        url: '/by-user/tenant/updateConfig',
        method: 'post',
        data: req
    })
}