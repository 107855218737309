import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "user-reported" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_table, {
      data: _ctx.data.reportedList,
      stripe: "",
      style: {"width":"100%"}
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_table_column, {
          prop: "title",
          label: "标题"
        }),
        _createVNode(_component_el_table_column, {
          prop: "createTime",
          label: "举报时间"
        }),
        _createVNode(_component_el_table_column, {
          prop: "creatorName",
          label: "举报人"
        }),
        _createVNode(_component_el_table_column, {
          prop: "userName",
          label: "被举报人"
        }),
        _createVNode(_component_el_table_column, {
          prop: "reportReason",
          label: "原因"
        })
      ]),
      _: 1
    }, 8, ["data"])
  ]))
}