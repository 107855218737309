import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_switch = _resolveComponent("el-switch")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_button = _resolveComponent("el-button")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_form, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_form_item, { label: "自动审批" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_switch, {
              modelValue: _ctx.data.tenantConfig.autoApprove,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.data.tenantConfig.autoApprove) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, { label: "主管理员" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              class: "form-input",
              maxlength: "11",
              modelValue: _ctx.data.tenantPhone,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.data.tenantPhone) = $event)),
              placeholder: "输入手机号",
              "show-word-limit": "",
              type: "text"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_el_button, {
      type: "primary",
      onClick: _ctx.data.submit
    }, {
      default: _withCtx(() => [
        _createTextVNode("提交")
      ]),
      _: 1
    }, 8, ["onClick"])
  ], 64))
}