
import { defineComponent,onMounted,reactive } from 'vue';
import {pageVerify, delVerify} from '@/http/api/verify';
import {time} from '@/utils/dateUtil';
import { ElMessage, ElMessageBox } from 'element-plus'

interface DataVerifyList{
    //认证列表
    verifies:Array<Object>;
    //总数据量
    total:any;
    //请求参数
    req:{
        pageNum:any,
        pageSize:any
    },
    //分页变更
    pageChange: (val:number) => void;
    //删除
    delete: (verify:any) => void;
    //修改认证
    update: (verify:any) => void;
    //审批认证
    approve: (verify:any) => void;
}
export default defineComponent({
    setup(props,{emit}) {
        onMounted(()=>{
            //获取认证列表
            pageVerify(data.req).then((res)=>{
                let list = res.data.data.list
                if (list !== null && list.length > 0) {
                    for(let i = 0; i< list.length; i++){
                        //处理时间
                        list[i].createTimeStr = time.long2Date(list[i].createTime)
                        //处理认证人数
                        if (list[i].passNum == null) {
                            list[i].passNum = 0
                        }
                    }
                }
                data.verifies = list
                data.total = Number(res.data.data.total)
            })
        })
        const data : DataVerifyList = reactive({
            verifies:[],
            total:0,
            req:{
                pageNum:1,
                pageSize:10
            },
            pageChange(val:number){
                console.log(val)
            },
            delete(verify){
                console.log(verify)
                ElMessageBox.confirm(
                    '是否删除认证？',
                    '警告',
                    {
                    confirmButtonText: '删除',
                    cancelButtonText: '取消',
                    type: 'warning',
                    }
                ).then(() => {
                    delVerify({id:verify.id}).then((res) =>{
                        //重新获取分页数据
                        this.page()
                        if (res.data.errorCode === 200) {
                            ElMessage({
                                type: 'success',
                                message: '删除',
                            })
                        }
                    })
                }).catch(() => {
                    ElMessage({
                        type: 'info',
                        message: '取消',
                    })
                })
            },
            page(){
                pageVerify(data.req).then((res)=>{
                    let list = res.data.data.list
                    if (list !== null && list.length > 0) {
                        for(let i = 0; i< list.length; i++){
                            //处理时间
                            list[i].createTimeStr = time.long2Date(list[i].createTime)
                            //处理认证人数
                            if (list[i].passNum == null) {
                                list[i].passNum = 0
                            }
                        }
                    }
                    data.verifies = list
                    data.total = Number(res.data.data.total)
                })
            },
            update(verify:any){
                emit("toUpdateVerify",verify.id)
            },
            approve(verify:any){
                emit("toApproveVerify",verify.id)
            }
        })
        return {data}
    },
})
