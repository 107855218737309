
import { defineComponent, onMounted, reactive } from 'vue';
import {queryConfig, updateConfig, addTenantAdmin, getMainAdmin} from "@/http/api/tenant";
import { ElMessage } from "element-plus";

interface DataTenantConfig {
    //租户配置
    tenantConfig:Object,
    //主管理员手机号
    tenantPhone:string,
    //提交
    submit:()=>void
}


export default defineComponent({
    props:["tenantId"],
    emits:["closeDrawer"],
    setup(props,{emit}) {
        onMounted(() => {
            let tenantId = props.tenantId
            //查询租户配置
            if (tenantId === null || tenantId === '') {
                emit('closeDrawer')
                return
            }
            //查询租户配置
            queryConfig({id:tenantId}).then((res) => {
                if (res.data.data !== null) {
                    console.log(res.data.data)
                    data.tenantConfig = res.data.data[0]
                }
            }),
            //查询租户主管理员
            getMainAdmin({id:props.tenantId}).then((res) => {
                console.log(res)
                if (res.data.data !== null) {
                    data.tenantPhone = res.data.data.phone
                }
            })
        })
        const data: DataTenantConfig = reactive({
            //租户配置
            tenantConfig:{},
            //主管理员手机号
            tenantPhone:"",
            //提交
            submit:() => {
                let req = data.tenantConfig
                updateConfig(req).then((res) => {
                    if (res.data.data) {
                        ElMessage.success("修改配置成功")
                    }
                });
                if (data.tenantPhone !== null && data.tenantPhone !== "") {
                    let req = {
                        tenantId: props.tenantId,
                        nickName: data.tenantPhone,
                        phone: data.tenantPhone,
                        password: data.tenantPhone
                    }
                    addTenantAdmin(req).then((res) => {
                        console.log(res)
                    })
                }
            }
        })
        return {data}
    },
})
