
import { defineComponent, reactive } from 'vue'

interface DataHomeMenu {
    //
    select : (i1:string,i2:string) => void;
}

export default defineComponent({
    props:[

    ],
    setup(props,{emit}) {
        const data : DataHomeMenu = reactive({
            select(i1:string,i2:string){
                if (i1 == '1') {
                    if (i2 == '1') {
                        //展示租户管理
                        emit("getMainType",'1-1')
                    }else if (i2 == '2') {
                        //租户配置
                        emit("getMainType",'1-2')
                    }
                }else if (i1 == '2') {
                    if (i2 == '1') {
                        //展示用户管理
                        emit("getMainType",'2-1')
                    }
                }else if (i1 == '3') {
                    if (i2 == '1') {
                        //展示认证管理
                        emit("getMainType",'3-1')
                    }else if (i2 == '2') {
                        //展示认证记录
                        emit("getMainType",'3-2')
                    }
                }else if (i1 == '4') {
                    if (i2 == '1') {
                        //展示数字大屏
                        emit("getMainType",'4-1')
                    }
                }
            }
        })
        return {data}
    },
})
