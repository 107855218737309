
import { defineComponent, onMounted, reactive } from 'vue';
import {getAllAutoType, getAllSubject, addVerify, updateVerify, detailVerify} from '@/http/api/verify'
import { da, el, fa } from 'element-plus/es/locale';

interface DataVerifyAdd {
    //操作类型 0：新增、1：修改
    handleType:number,
    //认证信息
    verify:{
        boolAuto: boolean,
        subjects:[]
    };
    //自动认证数据
    verifyAuto: Array<Object>;
    //所有认证主体
    verifySubjects: Array<Object>;
    //提交方法
    submit: () => void;
    
}
export default defineComponent({
    props:[
        "verifyId"
    ],
    setup(props,{emit}) {
        onMounted(() => {
            //获取所有自动认证类型
            getAllAutoType().then((res) => {
                data.verifyAuto = res.data.data
            })
            //获取所有认证主体
            getAllSubject().then((res) => {
                data.verifySubjects = res.data.data
            })
            if (props.verifyId !== null && props.verifyId !== '') {
                //获取要修改的认证信息
                detailVerify({id:props.verifyId}).then((res) => {
                    //转换认证信息
                    if (res.data.data !== null) {
                        let verify = res.data.data
                        if (verify.subjects !== null && verify.subjects.length > 0) {
                            let subjects = [];
                            for(let i=0;i<verify.subjects.length;i++){
                                subjects[i] = verify.subjects[i].subject
                            }
                            verify.subjects = subjects
                        }
                        data.verify = verify
                        data.handleType = 1
                    }
                })
            }
        })
        const data : DataVerifyAdd = reactive({
            handleType:0,
            verify:{
                boolAuto :false,
                subjects:[]
            },
            verifyAuto:[],
            verifySubjects:[],
            submit(){
                if (data.handleType == 1) {
                    //修改
                    updateVerify(data.verify).then((res) => {
                        if (res.data.errorCode == 200) {
                            emit("toTab","1")
                        }
                    })
                }else{
                    //新增
                    addVerify(data.verify).then((res) => {
                        if (res.data.errorCode == 200) {
                            emit("toTab","1")
                        }
                    })
                }
            }
        })
        return {data}
    },
})
