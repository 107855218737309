
import { defineComponent, reactive } from 'vue';
import VerifyRecordList from './verify-record-list.vue'

interface DataVerifyRecordMain {
    //页面类型
    pageType:string
}

export default defineComponent({
    setup() {
        const data : DataVerifyRecordMain = reactive({
            //页面类型
            pageType:''
        })
        return {data}
    },
    components:{
        VerifyRecordList
    }
})
