
import { defineComponent, onMounted, reactive } from "vue";
import { queryProvince, queryCity, queryCounty } from "@/http/api/org";
import { detailTenant, updateTenant, addTenant } from "@/http/api/tenant";
import { ElMessage } from "element-plus";
import { da, el } from "element-plus/es/locale";

interface DataTenantUpdate {
  //全部省份
  province: Array<Object>;
  //省下面的城市
  city: Array<Object>;
  //区县
  county: Array<Object>;
  //租户信息
  tenant: {
    //id
    id: string;
    name: string;
    //省code
    provinceCode: string;
    //市code
    cityCode: string;
    //区县code
    countyCode: string;
    //描述
    des: string;
  };
  //查询城市
  getCity: () => void;
  //查询区县
  getCounty: () => void;
  //跳转列表页
  toTable: () => void;
  //更新
  submit: () => void;
}

export default defineComponent({
  props: ["tenantId"],
  emits: ["setTable"],
  setup(props, { emit }) {
    onMounted(() => {
      //查询所有的省级信息
      queryProvince({}).then((res) => {
        if (res.data.data !== null) {
          data.province = res.data.data;
        }
      });
      let tenantId = props.tenantId;
      if (tenantId !== null && tenantId !== "") {
        //获取租户信息
        detailTenant({ id: tenantId }).then((res) => {
          if (res.data.data !== null) {
            let tenant = res.data.data;
            //查询市
            queryCity({ cityCode: tenant.cityCode }).then((res) => {
              if (res.data.data !== null) {
                data.city = res.data.data;
              }
            });
            //查询
            queryCounty({ countyCode: tenant.countyCode }).then((res) => {
              if (res.data.data !== null) {
                data.county = res.data.data;
              }
            });
            //设置租户
            data.tenant = tenant;
          }
        });
      }
    });
    const data: DataTenantUpdate = reactive({
      //省
      province: [],
      //市
      city: [],
      //区县
      county: [],
      tenant: {
        id: "",
        name: "",
        //默认选择河北省
        provinceCode: "13",
        //默认邯郸市
        cityCode: "",
        //区县code
        countyCode: "",
        //描述
        des: "",
      },
      //查询城市
      getCity: () => {
        if (
          data.tenant.provinceCode === null ||
          data.tenant.provinceCode === ""
        ) {
          ElMessage.error("请先选择省份信息");
          return;
        }
        let req = {
          provinceCode: data.tenant.provinceCode,
        };
        queryCity(req).then((res) => {
          console.log(res);
          if (res.data.data !== null) {
            data.city = res.data.data;
          }
        });
      },
      //查询区县
      getCounty: () => {
        if (data.tenant.cityCode === null || data.tenant.cityCode === "") {
          ElMessage.error("请先选择城市");
        }
        let req = {
          provinceCode: data.tenant.provinceCode,
          cityCode: data.tenant.cityCode,
        };
        queryCounty(req).then((res) => {
          console.log(res);
          if (res.data.data !== null) {
            data.county = res.data.data;
          }
        });
      },
      //跳转列表
      toTable: () => {
        emit("setTable");
      },
      //提交
      submit: () => {
        let tenant = data.tenant;
        if (tenant.id !== null && tenant.id !== "") {
          updateTenant(tenant).then((res) => {
            if (res.data.data) {
              ElMessage.success("修改成功");
              //跳转列表页
              data.toTable();
            }
          });
        } else {
          addTenant(tenant).then((res) => {
            if (res.data.data) {
              ElMessage.success("新增成功");
              //跳转列表页
              data.toTable();
            }
          });
        }
      },
    });
    return { data };
  },
});
