
import { defineComponent, onMounted, reactive } from "vue";
import { pageUser } from "@/http/api/user";

interface DataUserTable {
  req: {
    pageNum: number;
    pageSize: number;
  };
  //用户列表
  userList: Array<Object>;
  //总数
  total: number;

  //详情
  userDetail: (user: any) => void;
  //跳转被举报
  userReported: (user: any) => void;
  //切换分页
  pageChange: (val: any) => void;
}

export default defineComponent({
  setup(props, { emit }) {
    onMounted(() => {
      let req = {
        pageNum: 1,
        pageSize: 10,
      };
      //查询用户
      pageUser(req).then((res) => {
        console.log(res);
        if (res.data.data.list !== null) {
          data.userList = res.data.data.list;
          data.total = res.data.data.total;
        }
      });
    });
    const data: DataUserTable = reactive({
      //分页信息
      req: {
        pageNum: 1,
        pageSize: 10,
      },
      //用户列表
      userList: [],
      //总数
      total: 0,
      //用户详情
      userDetail: (user: any) => {
        emit("userDetail", user.id);
      },
      //跳转举报
      userReported: (user: any) => {
        emit("userReported", user.id);
      },
      //变更分页
      pageChange: (val: any) => {
        let req = data.req;
        req.pageNum = val;
        pageUser(req).then((res) => {
          if (res.data.data.list !== null) {
            data.userList = res.data.data.list;
            data.total = res.data.data.total;
          }
        });
      },
    });
    return { data };
  },
});
