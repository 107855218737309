
import { defineComponent, reactive } from 'vue';
import VerifyAdd from './verify-add.vue';
import VerifyList from './verify-list.vue';


interface DataVerify{
    //页面类型
    pageType:string
}
export default defineComponent({
    setup() {
        const data : DataVerify = reactive({
            pageType:''
        })
        return {data}
    },
    components:{VerifyAdd, VerifyList}
})
