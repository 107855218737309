
import { defineComponent, reactive } from 'vue';
import TenantTable from './tenant-table.vue';
import TenantDetail from './tenant-detail.vue';
import TenantUpdate from './tenant-update.vue';

interface DataTenant {
    //页面类型
    pageType:string;

    //租户id
    tenantId:number;

    //跳转列表
    toTable:() => void,

    //跳转详情
    setDetailTenantId:(tenantId:any) =>void,

    //跳转列表
    setTable:() => void,

    //跳转编辑
    tenantEdit:(tenantId:any) => void,
}

export default defineComponent({
    setup(props,{emit}) {
        const data : DataTenant = reactive({
            pageType:"table",
            tenantId:0,
            //跳转列表
            toTable:() => {
                data.pageType= 'table'
            },
            //跳转详情
            setDetailTenantId:(tenantId:any)=>{
                console.log(tenantId)
                data.pageType = 'detail',
                data.tenantId = tenantId
            },
            //跳转列表
            setTable:() => {
                data.pageType = 'table'
            },
            //跳转更新
            tenantEdit:(tenantId:any) => {
                console.log(tenantId)
                data.pageType = 'edit'
                data.tenantId = tenantId
            }
        })
        return {data}
    },
    components:{TenantTable, TenantDetail, TenantUpdate}
})
