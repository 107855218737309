export const time ={
    
    /**
     * 时间戳转string
     * @param n 时间戳
     * @returns 时间
     */
    long2Date(n:string){
        let now = new Date(Number(n)),
        y = now.getFullYear(),
        m = now.getMonth() + 1,
        d = now.getDate();
        return y + "-" + (m < 10 ? "0" + m : m) + "-" + (d < 10 ? "0" + d : d) + " " + now.toTimeString().substr(0, 8);
    }
}