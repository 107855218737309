
import { defineComponent, onMounted, reactive } from "vue";
import { delTenant, pageTenant } from "@/http/api/tenant";
import { ElMessageBox, ElMessage } from "element-plus";
import { queryProvince, queryCity } from "@/http/api/org";
import TenantConfig from "@/components/index/main/tenant/tenant-config.vue";

interface DataTenantTable {
  //租户列表
  tenants: Array<Object>;
  //查询条件
  req: {
    pageNum: any;
    pageSize: any;
    provinceCode: string;
    cityCode: string;
  };
  //省份
  province: Array<Object>;
  //城市
  city: Array<Object>;
  //数据量
  total: number;
  //配置抽屉
  configDrawer: boolean;
  //抽屉中的tenantId
  drawerTenantId: string;
  //新增租户
  add: () => void;
  //查询城市
  getCity: () => void;
  //查询
  query: () => void;
  //切换分页
  pageChange: (val: Number) => void;
  //获取租户详情
  tenantDetail: (tenant: any) => void;
  //编辑
  tenantEdit: (tenant: any) => void;
  //配置
  tenantConfig: (tenant: any) => void;
  //删除租户
  delTenant: (tenant: any) => void;
  //关闭抽屉
  closeDrawer: () => void
}

export default defineComponent({
  emits: ["setDetailTenantId", "tenantEdit"],
  setup(props, { emit }) {
    onMounted(() => {
      pageTenant(data.req).then((res) => {
        console.log(res.data.data.list);
        if (res.data.data !== null) {
          data.tenants = res.data.data.list;
          data.total = res.data.data.total;
        }
      });
      //获取省信息
      queryProvince({}).then((res) => {
        if (res.data.data !== null) {
          data.province = res.data.data;
        }
      });
    });
    const data: DataTenantTable = reactive({
      //租户列表
      tenants: [],
      //查询条件
      req: {
        pageNum: 1,
        pageSize: 10,
        provinceCode: "",
        cityCode: "",
      },
      //省
      province: [],
      //城市
      city: [],
      //数据量
      total: 0,
      //配置数据量
      configDrawer:false,
      //抽屉中的tenantId
      drawerTenantId:'',
      //新增租户
      add: () => {
        emit("tenantEdit", null);
      },
      getCity: () => {
        if (data.req.provinceCode === null || data.req.provinceCode === "") {
          ElMessage.error("请先选择省份信息");
          return;
        }
        let req = {
          provinceCode: data.req.provinceCode,
        };
        queryCity(req).then((res) => {
          if (res.data.data !== null) {
            data.city = res.data.data;
          }
        });
      },
      //查询
      query: () => {
        let req = data.req;
        pageTenant(req).then((res) => {
          console.log(res.data.data.list);
          if (res.data.data !== null) {
            data.tenants = res.data.data.list;
            data.total = res.data.data.total;
          }
        });
      },
      //切换分页
      pageChange(val: Number) {
        data.req.pageNum = val;
        let req = data.req;
        pageTenant(req).then((res) => {
          console.log(res.data.data.list);
          if (res.data.data !== null) {
            data.tenants = res.data.data.list;
            data.total = res.data.data.total;
          }
        });
      },
      //租户详情
      tenantDetail(tenant: any) {
        emit("setDetailTenantId", tenant.id);
      },
      //租户编辑
      tenantEdit(tenant: any) {
        console.log(tenant);
        emit("tenantEdit", tenant.id);
      },
      //租户配置
      tenantConfig: (tenant: any) => {
        data.drawerTenantId = tenant.id
        data.configDrawer = true
      },
      //删除租户
      delTenant: (tenant: any) => {
        console.log(tenant);
        ElMessageBox.confirm(
          "是否删除租户：" + tenant.name + " 的相关信息?",
          "警告",
          {
            confirmButtonText: "删除",
            cancelButtonText: "取消",
            type: "warning",
          }
        )
          .then(() => {
            delTenant({ id: tenant.id }).then((res) => {
              if (res.data.data) {
                ElMessage.success("删除成功");
                //重新请求页面数据
                pageTenant(data.req).then((res) => {
                  if (res.data.data !== null) {
                    data.tenants = res.data.data.list;
                    data.total = res.data.data.total;
                  }
                });
              }
            });
          })
          .catch(() => {});
      },
      //关闭配置抽屉
      closeDrawer:()=>{
        data.configDrawer = false;
        data.drawerTenantId = ''
      }
    });
    return { data };
  },
  components: { TenantConfig },
});
