
import { defineComponent, onMounted, reactive } from "vue";
import { detailTenant } from "@/http/api/tenant";

interface DataTenantDetail {
  //租户详情
  tenant: Object;
  //返回
  toTable:() => void,
  //前往更新
  tenantEdit:(tenantId:any) => void,
}

export default defineComponent({
  props: ["tenantId"],
  emits: ['setTable', 'tenantEdit'],
  setup(props, { emit }) {
    onMounted(() => {
      let tenantId = props.tenantId;
      //查询租户详情
      detailTenant({ id: tenantId }).then((res) => {
        if (res.data.data !== null) {
          console.log(res.data.data);
          data.tenant = res.data.data;
        }
      });
    });
    const data: DataTenantDetail = reactive({
        //租户详情
        tenant: {},
        //返回
        toTable:() => {
            emit('setTable')
        },
        //前往更新
        tenantEdit:(tenantId:any) => {
            emit('tenantEdit', tenantId)
        }
    });
    return { data };
  },
});
