import request from "../http";


/**
 * 查询所有省份信息
 */
export const queryProvince = (req: any) => {
    return request({
        url: '/by-user/org/queryProvince',
        method: 'post',
        data: req
    })
}

/**
 * 查询省份信息
 */
export const queryCity = (req: any) => {
    return request({
        url: '/by-user/org/queryCity',
        method: 'post',
        data: req
    })
}

/**
 * 查询区县  
 */
export const queryCounty = (req: any) => {
    return request({
        url: '/by-user/org/queryCounty',
        method: 'post',
        data: req
    })
}