
import { defineComponent, reactive } from "vue";
import UserTable from "./user-table.vue";
import UserDetail from "./user-detail.vue";
import UserReported from "./user-reported.vue";

interface DataUserMain {
  //页面类型
  pageType: string;
  //用户id
  userId: string;
  //去详情
  userDetail: (userId: any) => void;
  //去举报
  userReported: (userId: any) => void;
  //跳转列表
  toTable: () => void;
}

export default defineComponent({
  //
  emits: [],
  setup(props, { emit }) {
    const data: DataUserMain = reactive({
      //页面类型
      pageType: "table",
      //用户id
      userId: "",
      //去详情
      userDetail: (userId: any) => {
        data.pageType = "detail";
        data.userId = userId;
      },
      userReported: (userId: any) => {
        data.pageType = "reported";
        data.userId = userId;
      },
      //跳转列表
      toTable: () => {
        data.pageType = "table";
      },
    });
    return { data };
  },
  components: {
    UserTable,
    UserDetail,
    UserReported,
  },
});
