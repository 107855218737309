import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-76e1678a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "home-head" }
const _hoisted_2 = { class: "home-head-name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_avatar = _resolveComponent("el-avatar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_avatar, {
      size: 40,
      src: _ctx.data.user.headUrl
    }, null, 8, ["src"]),
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.data.user.nickName), 1)
  ]))
}