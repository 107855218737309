import request from "../http";


/**
 * 分页获取举报
 */
export const reportPage = (req:any) => {
    return request({
        url: "/by-content/report/reportPage",
        method: 'post',
        data: req
    })
}