import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7918d414"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "verify-record-main" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_breadcrumb_item = _resolveComponent("el-breadcrumb-item")!
  const _component_el_breadcrumb = _resolveComponent("el-breadcrumb")!
  const _component_el_card = _resolveComponent("el-card")!
  const _component_verify_record_list = _resolveComponent("verify-record-list")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_card, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_breadcrumb, { separator: "/" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_breadcrumb_item, { to: { path: '/' } }, {
              default: _withCtx(() => [
                _createTextVNode("认证记录列表")
              ]),
              _: 1
            }),
            (_ctx.data.pageType === 'detail')
              ? (_openBlock(), _createBlock(_component_el_breadcrumb_item, { key: 0 }, {
                  default: _withCtx(() => [
                    _createTextVNode("认证记录详情")
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_el_card, { class: "verify-record-list-content" }, {
      default: _withCtx(() => [
        _createVNode(_component_verify_record_list)
      ]),
      _: 1
    })
  ]))
}